



























































































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post6 extends Vue {}
