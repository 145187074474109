

































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post5 extends Vue {}
