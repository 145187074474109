




























































import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Resume extends Vue {
  mounted() {
    document.title = "Experience";
  }
}
