

















































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post3 extends Vue {
  get loopText() {
    return "    for (var i=0; i<balls.length; i++){\n      var ball = balls[i]; \n      ellipse(ball.position.x, ball.position.y,\n\t   ball.circleRadius);\n    }";
  }
}
