

























































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post16 extends Vue {}
