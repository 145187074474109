



















































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class About extends Vue {
  bgImgNum = 1;

  get bgImg() {
    // return `face${this.bgImgNum}`;
    return "face";
  }

  updateBg() {
    if (this.bgImgNum === 1) {
      this.bgImgNum = 2;
    } else {
      this.bgImgNum = 1;
    }
  }

  mounted() {
    document.title = "About";
  }
}
