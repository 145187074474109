// export
//     const categoryQuestions = [
//         {
//             name: "Cats of the Sahara",
//             questions: [
//                 {
//                     value: 200,
//                     text: "I don't know what any of this shit is, and I'm scared.",
//                 },
//                 {
//                     value: 400,
//                     text: "If I died tomorrow, no one would shed a tear.",
//                 },
//                 {
//                     value: 600,
//                     text: "No coffin! Just wet, wet mud.",
//                 },
//                 {
//                     value: 800,
//                     text: "Slopping down hog shit with these pig dicks!",
//                 },
//                 {
//                     value: 1000,
//                     text: "This red condiment can be put on hamburgers.",
//                 },
//             ],
//         },
//         {
//             name: "Monkey Business",
//             questions: [
//                 {
//                     value: 200,
//                     text: "It's kind of a cosmic gumbo.",
//                 },
//                 {
//                     value: 400,
//                     text: "Is it really in my Q Zone?",
//                 },
//                 {
//                     value: 600,
//                     text: "Your family hates you! Only I love you.",
//                 },
//                 {
//                     value: 800,
//                     text: "Don't bring me a bad deal.... Or I'll [guttural troll noises]",
//                 },
//                 {
//                     value: 1000,
//                     text: "You knew I'd love this gun.",
//                 },
//             ],
//         },
//         {
//             name: "Who's Hunting Who",
//             questions: [
//                 {
//                     value: 200,
//                     text: "Fuck, I should have lied!",
//                 },
//                 {
//                     value: 400,
//                     text: "This little fuckin' bike's a piece of shit.",
//                 },
//                 {
//                     value: 600,
//                     text: "It was uploaded at 6 this morning and it has one view.",
//                 },
//                 {
//                     value: 800,
//                     text: "Let's bust the fucker.",
//                 },
//                 {
//                     value: 1000,
//                     text: "[whispers] All Garfield",
//                 },
//             ],
//         },
//         {
//             name: "Monsters of the Deep",
//             questions: [
//                 {
//                     value: 200,
//                     text: "Yeah, but it's like a complete party house.",
//                 },
//                 {
//                     value: 400,
//                     text: "Never let the party die!!!",
//                 },
//                 {
//                     value: 600,
//                     text: "Whoa, I'd love to hear what she's playing, she is off the map.",
//                 },
//                 {
//                     value: 800,
//                     text: "I smoke five Macanudas a day.",
//                 },
//                 {
//                     value: 1000,
//                     text: "Why the fuck didn't you stick up for yourself?",
//                 },
//             ],
//         },
//         {
//             name: "Ghost House Tour",
//             questions: [
//                 {
//                     value: 200,
//                     text: "Do any of these fuckers ever bust out of the wall with a huge cumshot?",
//                 },
//                 {
//                     value: 400,
//                     text: "You think this is slicked back? This is PUSHED back.",
//                 },
//                 {
//                     value: 600,
//                     text: "Babysitter absolutely FUCKED us.",
//                 },
//                 {
//                     value: 800,
//                     text: "So the babysitter did art with the kids, that's cool.",
//                 },
//                 {
//                     value: 1000,
//                     text: "The tables keep my house hot!",
//                 },
//             ],
//         },
//         {
//             name: "Ghosts in Switzerland",
//             questions: [
//                 {
//                     value: 200,
//                     text: "Looks like Christmas came early!",
//                 },
//                 {
//                     value: 400,
//                     text: "Did I stutter, Megan?",
//                 },
//                 {
//                     value: 600,
//                     text: "A good steering wheel that doesn't fly off while you're driving.",
//                 },
//                 {
//                     value: 800,
//                     text: "I am doing the best at this.",
//                 },
//                 {
//                     value: 1000,
//                     text: "You admit it yourself that you suck.",
//                 },
//             ],
//         },
//     ];

export interface Question {
    value: number;
    text: string;
    isImage?: boolean;
}

export
    const categoryQuestions: { name: string; questions: Question[] }[] = [
        {
            name: "More Famous Than Paul Bunyan",
            questions: [
                {
                    value: 200,
                    text: "You don’t want to wrestle this former Minnesotan politician, or you might get bodied.",
                },
                {
                    value: 400,
                    text: "This famous handy man turned snitch had a two-year, 4 month vacation in a Sandstone, MN correctional facility.",
                },
                {
                    value: 600,
                    text: "St Louis Park, Minnesota may be No Country for Old Men, but it was the birth place of this movie making duo (who are indeed old men).",
                },
                {
                    value: 800,
                    text: "This Jazz Age author wasn’t born on This Side of Paradise but he was born in St Paul in 1896.",
                },
                {
                    value: 1000,
                    text: "This actress, born Frances Ethel Gumm, lived through a whirlwind of a life before passing in 1969 at age 47.",
                },
            ],
        },
        {
            name: "Double Trouble",
            questions: [
                {
                    value: 200,
                    text: "Over 850,000 visitors come to see this area annually. This location is spectacular even in the winter, but less exciting in a drought.",
                },
                {
                    value: 400,
                    text: "Spend a day on the town in this suburb where you can ride a roller coaster, bet on a horse, and visit your Aunt Ruth in Minnesota’s only women’s prison.",
                },
                {
                    value: 600,
                    text: "Shop till you drop in this Minnesota city that is home to the country’s largest indoor shopping center.",
                },
                {
                    value: 800,
                    text: "Hike around waterfalls, see some falcons, do some rock climbing, and jump in Lake Superior in this state park located off of scenic highway 61.",
                },
                {
                    value: 1000,
                    text: "This outspoken Minnesotan politician, a major figure in the Tea Party movement, went from the US House of Reps to running for president in 2012, but lost the Republican Nomination to Mitt Romney.",
                },
            ],
        },
        {
            name: "GDP (Groovy Domestic Products)",
            questions: [
                {
                    value: 200,
                    text: "Malus Pamila, was developed at the University of Minnesota in 1974, can be baked into pies but is known for being eaten plain and raw.",
                },
                {
                    value: 400,
                    text: "These kinds of cakes are missing their middle and their pans were invented in St Louis Park, MN.",
                },
                {
                    value: 600,
                    text: "This candy bar’s tastes reach out of this world with nougat, caramel, and chocolate.",
                },
                {
                    value: 800,
                    text: "When cabin pressure drops, you’ll be thankful for Minnesotans who invented this device found on airplanes.",
                },
                {
                    value: 1000,
                    text: "In 1966, Minneapolis was the birthplace of this game that you might not be comfortable playing with strangers.",
                },
            ],
        },
        {
            name: "Mni Sota Makoce",
            questions: [
                {
                    value: 200,
                    text: "This grain, known as Mahnonmin in Ojibwe has been a staple grain for many in the Great Lakes Region.",
                },
                {
                    value: 400,
                    text: "Unlike the tipi, this structure was a more permanent type of dwelling popularized by the Ojibwe tribe.",
                },
                {
                    value: 600,
                    text: "The Dakota used milk to demonstrate the meaning of Mnisota, which can be roughly translated to this.",
                },
                {
                    value: 800,
                    text: "Lake White Earth is the translated name for this popular Minnesotan lake.",
                },
                {
                    value: 1000,
                    text: "A national monument, this site is in Southwestern Minnesota and is a former quarry used by several tribes to create the object in its name.",
                },
            ],
        },
        {
            name: "Geography",
            questions: [
                {
                    value: 200,
                    text: "This city is the 3 rd largest by population in the state and sits outside the Twin Cities metro area.",
                },
                {
                    value: 400,
                    text: "At 2,301 feet this is the highest point in Minnesota and is located near the Boundary Waters.",
                },
                {
                    value: 600,
                    text: "Minnesota has 3 types of Koppen climates, however they fall under this type of climate family.",
                },
                {
                    value: 800,
                    text: "This former large glacial lake was larger than all the Great Lakes Combined and covered much of northern Minnesota",
                },
                {
                    value: 1000,
                    text: "This is the most popular lake name in Minnesota, with 201 lakes sharing this name.",
                },
            ],
        },
        {
            name: "iMNages",
            questions: [
                {
                    value: 200,
                    text: "Looks like Christmas came early!",
                    isImage: true
                },
                {
                    value: 400,
                    text: "Did I stutter, Megan?",
                    isImage: true
                },
                {
                    value: 600,
                    text: "A good steering wheel that doesn't fly off while you're driving.",
                    isImage: true
                },
                {
                    value: 800,
                    text: "I am doing the best at this.",
                    isImage: true
                },
                {
                    value: 1000,
                    text: "You admit it yourself that you suck.",
                    isImage: true
                },
            ],
        },
    ];