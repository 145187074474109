























































import { Component, Vue } from "vue-property-decorator";
import { projects } from "@/projectsList";

@Component
export default class Projects extends Vue {
  projects = projects;

  mounted() {
    document.title = "Projects";
  }

  getThumbnailStyle(proj: any) {
    return {
      backgroundImage: `url('${this.getPreviewImageUrl(proj)}')`,
    };
  }

  clickImg(proj: any) {
    // for now, just go to demo?
    // this.$router.push({ redirect: window.location.href = this.getDemoUrl(proj) });
    // window.navigator.ur
    window.location.href = this.getDemoUrl(proj);
  }

  getDemoUrl(proj: any) {
    if (proj.repo === "bard") return "https://www.webofthebard.com";
    return `https://zackstout.github.io/${proj.repo}`;
  }

  getRepoUrl(proj: any) {
    return `https://github.com/zackstout/${proj.repo}`;
  }

  getPreviewImageUrl(proj: any) {
    if (proj.previewImageUrl) {
      return proj.previewImageUrl;
    }
    return `~@/assets/projects/${proj.repo}.png`;
  }
}
