


























































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post9 extends Vue {
  cardioidPosition = 1;

  running = false;

  interval: any = null;

  start() {
    this.running = true;
  }
  stop() {
    this.running = false;
  }

  mounted() {
    console.log("mount");
    this.interval = setInterval(() => {
      this.circleCard(1, 1, 200, 300, this.cardioidPosition);
      if (this.running) {
        this.cardioidPosition += 0.01;
      }
    }, 10);
  }

  beforeDestroy() {
    clearInterval(this.interval);
  }

  //   function setup() {
  //     var can = createCanvas(800, 800);
  //   }

  //   function draw() {
  //     clear();
  //     translate(400, 400);
  //     circleCard(1, 1, 200, 300, cardioidPosition);
  //     cardioidPosition += 0.01;
  //     translate(-400, -400);
  //     $('.num').html(cardioidPosition.toFixed(2));
  //   }

  //x is how far you iterate, r is radius, f controls number of flower petals or nodes:
  circleCard(a: number, b: number, x: number, r: number, f: number) {
    const ctx: any = (document.getElementById("cardioid") as HTMLCanvasElement).getContext("2d");
    // ctx.fill("white");
    ctx.clearRect(0, 0, 600, 600);
    ctx.strokeStyle = "blue";
    ctx.translate(300, 300);

    for (var i = 0; i < x; i++) {
      var start = { xCoord: r * a * Math.cos((i * 2 * Math.PI) / x), yCoord: r * b * Math.sin((i * 2 * Math.PI) / x) };
      var doubleStart = (f * i) % x;
      var end = {
        xCoord: r * a * Math.cos((doubleStart * 2 * Math.PI) / x),
        yCoord: r * b * Math.sin((doubleStart * 2 * Math.PI) / x),
      };

      ctx.beginPath();
      ctx.moveTo(start.xCoord, start.yCoord);
      ctx.lineTo(end.xCoord, end.yCoord);

      //   console.log(start.xCoord, start.yCoord, end.xCoord, end.yCoord);

      ctx.stroke();

      //   line(start.xCoord, start.yCoord, end.xCoord, end.yCoord);
      //   stroke(color('darkblue'));
    }

    ctx.translate(-300, -300);
  }
}
