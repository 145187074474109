import { render, staticRenderFns } from "./Jeopardy.vue?vue&type=template&id=6fd9d7b6&"
import script from "./Jeopardy.vue?vue&type=script&lang=ts&"
export * from "./Jeopardy.vue?vue&type=script&lang=ts&"
import style0 from "./Jeopardy.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports