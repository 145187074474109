






























































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post4 extends Vue {}
