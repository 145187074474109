
















































































































import { Component, Vue } from "vue-property-decorator";

@Component
export default class Post10 extends Vue {}
