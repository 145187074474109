






























import { Component, Vue } from "vue-property-decorator";
import { categoryQuestions, Question } from "./questions";

@Component({
  components: {},
})
export default class App extends Vue {
  categoryQuestions = categoryQuestions;
  gridPosition = { x: 0, y: 0, w: 0, h: 0 };

  mounted() {
    const box = document.getElementById("grid")?.getBoundingClientRect();
    this.gridPosition.x = box?.x || 0;
    this.gridPosition.y = box?.y || 0;
    this.gridPosition.w = box?.width || 0;
    this.gridPosition.h = box?.height || 0;
    console.log(categoryQuestions, this.gridPosition);

    window.addEventListener("resize", () => {
      const box = document.getElementById("grid")?.getBoundingClientRect();
      this.gridPosition.x = box?.x || 0;
      this.gridPosition.y = box?.y || 0;
      this.gridPosition.w = box?.width || 0;
      this.gridPosition.h = box?.height || 0;
    });

    // Preload all images (not really helping unfortunately..)
    [200, 400, 600, 800, 1000].forEach((value) => {
      const newImage = new Image();
      newImage.src = require(`@/components/Jeopardy/assets/${value}.png`);
      newImage.onload = () => {
        console.log("Loaded 'er up!");
      };
    });
  }

  getCardStyle(q: Question) {
    if (!q.isImage) {
      return {};
    }

    return {
      backgroundImage: `url("${this.getImageSrc(q)}")`,
    };
  }

  getImageSrc(q: Question) {
    const minSuffix = [200, 600].includes(q.value) ? "-min" : "";
    console.log(minSuffix);
    return require("@/components/Jeopardy/assets/" + q.value + minSuffix + ".png");
  }

  // [x] Must get rid of, if click after is opened.
  clickQuestion(ev: MouseEvent) {
    // @ts-ignore
    const currentPosition = ev.target.getBoundingClientRect();

    // @ts-ignore
    const clickedBackInner = Array.from(ev.target.classList).some((className) => className === "back-inner");
    // @ts-ignore
    const parent = clickedBackInner ? ev.target.parentElement.parentElement : ev.target.parentElement;

    // Ignore clicks on borders
    if (!Array.from(parent.classList).some((className: any) => className.includes("inner"))) return;

    if (parent.dataset.flipped === "true") {
      // Send card back to original location
      // parent.style.transition = "unset";
      // parent.style.transformOrigin = "0 0";
      // parent.style.transform = "scale(0.94)";
      // parent.style.width = `${92 / 6}vw`;
      // parent.style.height = `${80 / 6}vh`;
      // parent.parentElement.style.zIndex = 1;
      // parent.dataset.flipped = "false";

      parent.style.display = "none";
      parent.parentElement.style.background = "linear-gradient(to top left, #0000ca, #0000ff)";
      parent.parentElement.style.zIndex = "0"; // Seems to fix really weird bug with cells showing up over newly-clicked question
      return;
    } else {
      // Flip card (with css transition)
      parent.style.transition = "all 0.8s";
      parent.dataset.flipped = "true";

      // This gets rid of text-moving issue on flip
      // @ts-ignore
      parent.querySelector(".back").style.width = this.gridPosition.w + "px";
    }

    const xMove = this.gridPosition.x - currentPosition.x;
    const yMove = this.gridPosition.y - currentPosition.y;
    // Again... unsure why x is negative... Oh! Probably because we flip on y-axis first!
    parent.style.transform = `rotateY(180deg) translate(${-xMove}px, ${yMove}px)`;
    // Transform scale would have been nice, but made text look grainy, so just set width/height instead
    parent.style.width = this.gridPosition.w + "px";
    parent.style.height = this.gridPosition.h + "px";
    parent.parentElement.style.zIndex = 1000;
  }

  /**
   * Few issues
   *
   * 1. [x] On card flip, text changes width -- easy set width to 100vw
   * 2. [x] Cannot select a card again after flipping it -- easy, we weren't reseting flipped flag... and strings, not booleans, lol
   * 3. [x] Add padding to backside....somehow....
   * (We can add a "back-inner" div... but then clicking directly on the text does NOT close the card.. Yeah, working with dumb hack)
   * 4. [x] If you click on border....whole column flips!
   * 5. [x] Real font
   * 6. [?] Do we need websockets??
   *
   * 7. [ ] Turn the cell just plain background, after it has been flipped.
   *
   * 8. [ ] Borders get kinda f-ed up
   *
   * 9. [ ] Sometimes text fade is funky
   */
}
